import FontFaceObserver from 'fontfaceobserver';

import {
  OWNER_TYPE_ENTITY,
  TAX_STATUS_QUALIFIED,
  TAX_STATUS_NON_QUALIFIED,
  MAX_PERSON_INCOME_AGE,
  MIN_YEARS_OF_DEFERRAL,
  MAX_YEARS_OF_DEFERRAL,
  MAX_YEARS_OF_DEFERRAL_ACCELERATOR,
  MIN_PERSON_INCOME_AGE,
  Advantage,
  Strategies,
  MAX_PARTIAL_WITHDRAWALS_ALLOWED_ADVANCE_STATES
} from '../constants';

export const getCurrencyFormat = valueParam => {
  let value = valueParam;
  value += '';
  const x = value.split('.');
  let x1 = x[0];
  const x2 = x.length > 1 ? `.${x[1]}` : '';
  const rgx = /(\d+)(\d{3})/;
  while (rgx.test(x1)) {
    x1 = x1.replace(rgx, `${'$1'}${','}${'$2'}`);
  }
  return `$${x1}${x2}`;
};

export const getFirstName = value => {
  const values = value.split(' ');
  return values[0];
};

export const getMiddleName = value => {
  const values = value.split(' ');
  if (values.length > 2) {
    return values[1];
  }
  return '';
};

export const getLastName = value => {
  const values = value.split(' ');
  if (values.length >= 3) {
    let finalValue = '';
    for (let x = 2; x < values.length; x += 1) {
      finalValue += `${values[x]} `;
    }
    return finalValue.trim();
  }
  return values[1];
};

export const isDateFormat = date => /^\d{2}([/])\d{2}\1\d{4}$/.test(date);

export const isCurrencyFormat = value => /\$\d[.,]?/.test(value);

export const isPercentFormat = value => /\d+(%)/.test(value);

export const getYearsDateOfBirth = date => {
  const today = new Date();
  const birthDate = new Date(date);
  let age = today.getFullYear() - birthDate.getFullYear();
  const m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age -= 1;
  }
  return age;
};

export const isSafari =
  /Safari/.test(navigator.userAgent) && /Apple Computer/.test(navigator.vendor);

/**
 * Get numbers and decimals from a formatted value, otherwise returns null
 * @param {string} value Formatted value
 */
export const getNumbersAndDecimalsOnly = value =>
  typeof value === 'string' ? (value.match(/\d*[.]?/g) || []).join('') : null;

export const splitDecimal = (numStrParam, allowNegative = true) => {
  const hasNagation = numStrParam[0] === '-';
  const addNegation = hasNagation && allowNegative;
  const numStr = numStrParam.replace('-', '');

  const parts = numStr.split('.');
  const beforeDecimal = parts[0];
  const afterDecimal = parts[1] || '';

  return {
    beforeDecimal,
    afterDecimal,
    hasNagation,
    addNegation
  };
};

export const limitToScale = (numStr, scale, fixedDecimalScale) => {
  let str = '';
  const filler = fixedDecimalScale ? '0' : '';

  for (let i = 0; i <= scale - 1; i += 1) {
    str += numStr[i] || filler;
  }
  // if (str === '00') {
  //   return '';
  // }
  return str;
};

export const applyThousandSeparator = (str, thousandSeparator) => {
  const thousandsGroupRegex = /(\d)(?=(\d{3})+(?!\d))/g;
  let index = str.search(/[1-9]/);
  index = index === -1 ? str.length : index;
  return (
    str.substring(0, index) +
    str
      .substring(index, str.length)
      .replace(thousandsGroupRegex, `$1${thousandSeparator}`)
  );
};

export const removeCharacter = (strToRemove, str) => {
  const reg = new RegExp(strToRemove);
  return str.replace(reg, '');
};

export const createCSVDownload = (csv, filename) => {
  const blob = new Blob([csv], { type: 'text/csv' });

  /* taken from react-csv */
  if (navigator && navigator.msSaveOrOpenBlob) {
    navigator.msSaveOrOpenBlob(blob, filename);
  } else {
    const dataURI = `data:text/csv;charset=utf-8,${csv}`;

    const URL = window.URL || window.webkitURL;
    const downloadURI =
      typeof URL.createObjectURL === 'undefined'
        ? dataURI
        : URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.setAttribute('href', downloadURI);
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
};

export const formatNumberWithDecimals = (numStrParam, scale = 2) => {
  let numStr = numStrParam;
  numStr += '';
  // split decimals
  let { beforeDecimal, afterDecimal, addNegation } = splitDecimal(numStr, true);
  // appends zeros
  if (afterDecimal) {
    afterDecimal = limitToScale(afterDecimal, scale, true);
  }
  // has decimal
  const hasDecimalSeparator = numStr.indexOf('.') !== -1 && !!afterDecimal;
  // add negation
  if (addNegation) beforeDecimal = `-${beforeDecimal}`;
  // thousands format
  beforeDecimal = applyThousandSeparator(beforeDecimal, ',');
  return `${beforeDecimal}${hasDecimalSeparator ? '.' : ''}${afterDecimal}`;
};

/**
 * Get a number and decimals with currency format 10.20% or 10%
 * @param {string} numStr number to be formatted
 * @param {scale} scale number of decimals to display
 */
export const getCurrencyFormatWithDecimals = (numStr, scale = 2) =>
  `$${formatNumberWithDecimals(numStr, scale)}`;
/**
 * Get a number and decimals with percent format 10.20% or 10%
 * @param {string} numStr number to be formatted
 * @param {scale} scale number of decimals to display
 */
export const getPercentFormat = (numStr, scale = 2) =>
  `${formatNumberWithDecimals(numStr, scale)}%`;

export const bindEvent = (element, eventName, eventHandler) => {
  if (element.addEventListener) {
    element.addEventListener(eventName, eventHandler, false);
  } else if (element.attachEvent) {
    element.attachEvent(`on${eventName}`, eventHandler);
  }
};

export const removeEvent = (element, eventName, eventHandler) => {
  if (element.removeEventListener) {
    element.removeEventListener(eventName, eventHandler, false);
  } else if (element.detachEvent) {
    element.detachEvent(`on${eventName}`, eventHandler);
  }
};

export const inIframe = () => {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
};

export const removeParamFromURL = (key, sourceURL) => {
  let rtn = sourceURL.split('?')[0];
  let param;
  let paramsArr = [];

  const queryString =
    sourceURL.indexOf('?') !== -1 ? sourceURL.split('?')[1] : '';
  if (queryString !== '') {
    paramsArr = queryString.split('&');
    for (let i = paramsArr.length - 1; i >= 0; i -= 1) {
      [param] = paramsArr[i].split('=');
      if (param === key) {
        paramsArr.splice(i, 1);
      }
    }
    if (paramsArr.length > 0) {
      rtn = `${rtn}?${paramsArr.join('&')}`;
    }
  }
  return rtn;
};

export const toQueryString = params =>
  Object.keys(params)
    .filter(key => params[key] !== '')
    .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
    .join('&');

const getYoungestAge = (clientAge, secondAge, isSecondPerson) =>
  isSecondPerson && secondAge
    ? Math.min(clientAge || 0, secondAge)
    : clientAge || 0;

export const getOldestAge = (clientAge, secondAge, isSecondPerson) =>
  isSecondPerson && secondAge
    ? Math.max(clientAge || 0, secondAge)
    : clientAge || 0;

export const getMaxYearsOfDeferral = product =>
  product === Advantage
    ? MAX_YEARS_OF_DEFERRAL
    : MAX_YEARS_OF_DEFERRAL_ACCELERATOR;

export const getEnhancedIncomeRange = (
  owner,
  isSecondPerson,
  incomeBenefitClientAge,
  incomeBenefitSecondPerson
) => {
  const yearsOfDeferral = incomeBenefitClientAge - owner.age;
  const maxYearsOfDeferral = MAX_YEARS_OF_DEFERRAL;
  const diffYearsOfDeferral = maxYearsOfDeferral - yearsOfDeferral;

  const oldestAge = getOldestAge(
    incomeBenefitClientAge,
    incomeBenefitSecondPerson,
    isSecondPerson
  );
  // First Person
  let minAgeFirstPerson = incomeBenefitClientAge || MIN_PERSON_INCOME_AGE;
  if (
    minAgeFirstPerson < MIN_PERSON_INCOME_AGE ||
    minAgeFirstPerson > MAX_PERSON_INCOME_AGE
  ) {
    minAgeFirstPerson = MIN_PERSON_INCOME_AGE;
  }
  const rangeDiferrals =
    diffYearsOfDeferral + oldestAge > MAX_PERSON_INCOME_AGE
      ? MAX_PERSON_INCOME_AGE - oldestAge
      : diffYearsOfDeferral;

  const result = {
    firstPerson: {
      minAge: minAgeFirstPerson,
      maxAge: minAgeFirstPerson + rangeDiferrals
    }
  };
  // Second Person
  if (isSecondPerson) {
    let minAgeSecondPerson = incomeBenefitSecondPerson || MIN_PERSON_INCOME_AGE;
    if (
      minAgeSecondPerson < MIN_PERSON_INCOME_AGE ||
      minAgeSecondPerson > MAX_PERSON_INCOME_AGE
    ) {
      minAgeSecondPerson = MIN_PERSON_INCOME_AGE;
    }

    result.secondPerson = {
      minAge: minAgeSecondPerson,
      maxAge: minAgeSecondPerson + rangeDiferrals
    };
  }

  return result;
};

const getSecondIncomeBenefitValues = (
  clientAge,
  secondPersonAge,
  incomeStartAge,
  minYearsOfDeferral,
  maxYearsOfDeferral,
  isSecondPerson
) => {
  let jointMinAge = 0;
  let jointMaxAge = 0;
  let secondIncomeStartAge = 0;

  if (isSecondPerson) {
    jointMinAge = minYearsOfDeferral + secondPersonAge;
    jointMaxAge = maxYearsOfDeferral + secondPersonAge;
    jointMaxAge =
      jointMaxAge > MAX_PERSON_INCOME_AGE ? MAX_PERSON_INCOME_AGE : jointMaxAge;

    secondIncomeStartAge = incomeStartAge - clientAge + secondPersonAge;
  }

  return {
    minAge: jointMinAge,
    maxAge: jointMaxAge,
    incomeStartAge: secondIncomeStartAge
  };
};

export const getIncomeBenefitAgeRange = values => {
  const { isSecondPerson, owner, secondPerson, product } = values;
  const clientAge = owner.age || 0;
  const secondPersonAge = secondPerson.age || 0;
  const clientCurrentIncomeStartAge = values.incomeBenefit.startAge || 0;

  const youngestAge = getYoungestAge(
    clientAge,
    secondPersonAge,
    isSecondPerson
  );

  const minYearsOfDeferral =
    MIN_PERSON_INCOME_AGE - youngestAge >= MIN_YEARS_OF_DEFERRAL
      ? MIN_PERSON_INCOME_AGE - youngestAge
      : MIN_YEARS_OF_DEFERRAL;

  const maxYearsOfDeferral = getMaxYearsOfDeferral(product);

  const minAge = minYearsOfDeferral + clientAge;
  let maxAge = clientAge + maxYearsOfDeferral;
  maxAge = maxAge > MAX_PERSON_INCOME_AGE ? MAX_PERSON_INCOME_AGE : maxAge;

  let incomeStartAge = minAge;
  incomeStartAge =
    clientCurrentIncomeStartAge <= minAge
      ? minAge
      : clientCurrentIncomeStartAge;
  incomeStartAge = incomeStartAge > maxAge ? maxAge : incomeStartAge;

  return {
    owner: {
      minAge,
      maxAge,
      incomeStartAge
    },
    secondPerson: getSecondIncomeBenefitValues(
      clientAge,
      secondPersonAge,
      incomeStartAge,
      minYearsOfDeferral,
      maxYearsOfDeferral,
      isSecondPerson
    )
  };
};

export const focusFirstError = (form, conditionalCallback) => {
  const fields = Object.keys(form);
  // eslint-disable-next-line no-plusplus
  for (let i = 0, len = fields.length; i < len; i++) {
    const key = fields[i];
    const currentField = form[key];
    if (conditionalCallback(currentField)) {
      currentField.focus();
      break;
    }
  }
};

export const waitForFonts = (names = []) =>
  Promise.all(
    names.map(name =>
      new FontFaceObserver(name).load().catch(err => {
        console.warn(`Error loading font ${name}`, err);
      })
    )
  );

export async function tryLoadFonts() {
  try {
    await waitForFonts([
      'TTNormsProCondensed-DmBd',
      'TTNormsProCondensed',
      'TTNormsProCondensed-Bd',
      'TTNormsPro-Italic',
      'TTNormsPro-DmBd',
      'TTNormsPro-Normal',
      'TTNormsPro-Bd'
    ]);
  } catch (err) {
    console.warn('Could not load fonts', err);
  }
}

export const capitalize = value =>
  value && value.replace(/\b\w/g, l => l.toUpperCase());

export const generateListOfAges = state => {
  let array = [...Array(120)].map((_, i) => ({
    name: i + 1,
    value: i + 1
  }));

  if (!MAX_PARTIAL_WITHDRAWALS_ALLOWED_ADVANCE_STATES.includes(state)) {
    array = array.splice(1, array.length - 1);
  }

  return array;
};

export const isQualifiedType = values =>
  values.taxStatus === TAX_STATUS_QUALIFIED;

export const isNonQualifiedType = values =>
  values.taxStatus === TAX_STATUS_NON_QUALIFIED;

export const isEntityType = values =>
  isNonQualifiedType(values) && values.ownerType === OWNER_TYPE_ENTITY;

export const getOldestPerson = (
  owner,
  isSecondPerson,
  secondPerson,
  ownerType,
  taxStatus
) => {
  if (!isSecondPerson) return owner;

  if (owner.coveredPerson && secondPerson.coveredPerson) {
    if (owner.age > secondPerson.age) return owner;
    if (owner.age < secondPerson.age) return secondPerson;
    // if owner and second person are the same age, based on ownership
    const isEntity = isEntityType({ ownerType, taxStatus });
    if (!isEntity && owner.owner) return owner;
    if (!isEntity && secondPerson.jointOwner) return secondPerson;
    return owner;
  }
  return owner.coveredPerson && !secondPerson.coveredPerson
    ? owner
    : secondPerson;
};

export const convertToPercent = value =>
  (value * 100).toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];

export const getDeferralError = (yearsOfDeferral, product) => {
  const deferralError = {};
  const maxYearsOfDeferral = getMaxYearsOfDeferral(product);
  if (yearsOfDeferral !== undefined) {
    if (yearsOfDeferral < MIN_YEARS_OF_DEFERRAL) {
      deferralError.type = 'Minimum';
      deferralError.years = MIN_YEARS_OF_DEFERRAL;
    } else if (yearsOfDeferral > maxYearsOfDeferral) {
      deferralError.type = 'Maximum';
      deferralError.years = maxYearsOfDeferral;
    }
  }
  return deferralError;
};

const isMobile = () =>
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  ) || typeof window.orientation !== 'undefined';

export const focusToFirstInput = form => {
  try {
    if (!form || isMobile()) return;
    const fields = Object.keys(form);
    for (let i = 0, len = fields.length; i < len; i += 1) {
      const key = fields[i];
      const currentField = form[key];
      if (!currentField.disabled) {
        currentField.focus();
        break;
      }
    }
    // eslint-disable-next-line no-empty
  } catch (e) {}
};

export const toFixedNoRounding = (amount, decimals) => {
  const reg = new RegExp(`^-?\\d+(?:\\.\\d{0,${decimals}})?`, 'g');
  const newAmount = amount.toString().match(reg)[0];
  return Number(newAmount);
};

export const isFiveorTenStrategy = strategies =>
  strategies &&
  strategies.length > 0 &&
  (strategies[0].strategyName === Strategies.Five ||
    strategies[0].strategyName === Strategies.Ten);

export const paramsToObject = entries => {
  const result = {};
  for (const [key, value] of entries) {
    result[key] = value;
  }
  return result;
};

export const cleanQuerystring = querystring =>
  querystring.replace('input=', '');

export const formatRegisterMarksCharacters = text => {
  let formattedText = text.slice();
  if (text?.includes('℠')) {
    formattedText = text.replace(
      new RegExp('℠', 'g'),
      '<sup class="service-mark">SM</sup>'
    );
  }
  if (text?.includes('™')) {
    formattedText = text.replace(
      new RegExp('™', 'g'),
      '<sup class="trade-mark">TM</sup>'
    );
  }
  const result = !formattedText ? '' : formattedText;
  return result;
};
